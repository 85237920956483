<script lang="ts">
	import type { HTMLAnchorAttributes, HTMLButtonAttributes } from 'svelte/elements';

	type ButtonProps = HTMLButtonAttributes & { href?: undefined };
	type AnchorProps = HTMLAnchorAttributes & { href: string, rel?: string };
	type Props = ButtonProps | AnchorProps;

	type Variant = 'primary' | 'outline' | 'white' | 'barmenia' | 'finanzguru' | 'roland';

	const variants = {
		primary: 'border-primary bg-primary border border-solid px-6 py-2.5 text-white',
		outline: 'border-primary border border-solid px-6 py-2.5 text-primary',
		white: 'border-white border border-solid px-6 py-2.5 text-white bg-transparent',
		barmenia: 'border-[#009fe3] bg-[#009fe3] border border-solid px-6 py-2.5 text-white',
		roland: 'border-[#0360A7] bg-[#0360A7] border border-solid px-6 py-2.5 text-white',
		finanzguru: 'border-[#3c318f] bg-[#3c318f] border border-solid px-6 py-2.5 text-white',
	};

	let {
		class: className,
		variant = 'primary',
		children,
		...props
	}: Props & { variant?: Variant } = $props();
	let isAnchor = $derived('href' in props && typeof props.href === 'string');
	let element = $derived(isAnchor ? 'a' : 'button');

	const variantClass = $derived(variants[variant]);
</script>

<svelte:element this={element} class="{variantClass}{className}" {...props}>
	{@render children?.()}
</svelte:element>
